.Root {
	height: 100vh;
	background-color: #121213;
}

.App {
	text-align: center;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
